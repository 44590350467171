<template>
  <div class="portrayPage oepnX">
    <div class="content" v-if="!isLoading">
      <div class="atlas" v-if="atlasData" @click="popupShow = true">
        <!--            <span>{{atlasData}}</span>-->
        <div v-for="(item, index) in atlasData.contents" :key="index">
          <div class="atlasItem" v-for="itemUrl in item.urlSet" :key="itemUrl">
            <ImgDecypt
              v-if="itemUrl"
              :src="itemUrl"
              :class="{ previewImg: !loadImg }"
              @load="onLoad"
            ></ImgDecypt>
          </div>
        </div>
        <!-- <div class="advertising" v-if="advInfo" @click.stop="goAdv(advInfo)">
                    <ImgDecypt class="advImg" :key="advInfo.cover" :src="advInfo.cover" />
                </div> -->
        <div class="advertising">
          <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in advInfo"
              :key="index"
              @click="goAdv(item)"
            >
              <ImgDecypt class="advImg" :src="item.cover" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
    <Loading v-else />
    <van-overlay :show="popupShow" @click="popupShow = false">
      <van-popup
        v-model="popupShow"
        :overlay="false"
        position="top"
        :style="{ height: '44px', background: 'rgb(255,255,255)' }"
      >
        <!-- <div @click.stop>
          <HeadBar
            @click.stop
            :titleName="atlasData && atlasData.title"
            titleLocation="left"
          ></HeadBar>
        </div> -->
        <div @click.stop v-if="atlasData">
          <div class="headBar">
            <div class="leftIcon" @click="$router.go(-1)">
              <img src="@/assets/png/left.png" />
            </div>
            <div class="headTitle ellipsis">
              <span>{{ atlasData && atlasData.title }}</span>
            </div>
            <!-- 收藏 -->
            <div
              class="fictionCollect"
              v-if="atlasData && !atlasData.mediaStatus.hasCollected"
              @click="collectionWorks"
            >
<!--              <img src="@/assets/png/isnovelCollect.png" />-->
              <span>+&nbsp;收藏</span>
            </div>

            <div
              class="fictionCollect"
              v-else-if="atlasData"
              @click="cancelCollectionWorks"
            >
<!--              <img src="@/assets/png/hasnovelCollect.png" />-->
              <span class="isCollect">已收藏</span>
            </div>
          </div>
        </div>
      </van-popup>
      <van-popup
        v-model="popupShow"
        :overlay="false"
        position="bottom"
        :style="{ height: '59px', background: 'rgb(255,255,255)' }"
      >
        <div class="bottomBar" @click.stop>
          <div class="up" @click.stop="backPage">
            <svg-icon class="icon" iconClass="leftUp" />
            <span>上一篇</span>
          </div>
          <div class="down" @click="nextPage">
            <span>下一篇</span>
            <svg-icon class="icon" iconClass="leftUp" />
          </div>
        </div>
      </van-popup>
    </van-overlay>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import {doPlay, queryMediaInfoList} from "@/api/video";
import ImgDecypt from "@/components/ImgDecypt";
import HeadBar from "@/components/HeadBar";
import { addVideoHistory } from "@/utils";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import { doCollect, douUcollect } from "@/api/video";
import { Toast } from "vant";
export default {
  name: "PortrayPage",
  components: {
    ImgDecypt,
    Loading,
    // HeadBar,
  },
  data() {
    return {
      mediaID: null,
      kind: null,
      atlasData: null,
      show: false,
      isLoading: true,
      loadImg: false,
      popupShow: false,
      advInfo: null,
      photoList: [],
      currIndex: 0,
      flag: true,
    };
  },
  mounted() {
    let query = this.$route.query;
    this.currIndex = query.phoneIndex || 0;
    if (query) {
      this.mediaID = query.id;
      this.kind = query.kind;
    }
    if (query.list) {
      let dataStr = this.$route.query.list;
      let decodeData = window.atob(decodeURI(dataStr));
      this.photoList = JSON.parse(decodeURI(window.decodeURI(decodeData)));
      // console.log(this.photoList)
    }
    this.getMediaInfoList();

    let advList = getAdItem(AdType.CREATION_CENTER);
    if (advList.length > 0) {
      this.advInfo = advList;
    }
  },
  beforeDestroy() {
   this.doPlayWorks();
  },
  methods: {
    async getMediaInfoList() {
      let _this = this;

      let req = {
        // mediaID: this.mediaID
        mediaID: this.photoList[this.currIndex].id,
      };
      let res = await _this.$Api(queryMediaInfoList, req);

      _this.isLoading = false;

      if (res && res.code == 200) {
        //vip
        if (res.data.permission == 0) {
          if (!_this.$store.getters.isVip) {
            _this.$bus.$emit("vipPopup", {
              state: 1,
              id: this.photoList[this.currIndex].id,
              videoType: this.photoList[this.currIndex].kind,
              closeBtn: () => {
                if (this.flag) {
                 this.$bus.$emit("closeVipPopup");
                 this.$router.go(-1);
                 this.flag = false;
                }
              },
              buySuccess: () => {
                /**
                 * 金币购买成功
                 */
                _this.$bus.$emit("closeVipPopup");
                this.atlasData.mediaStatus.hasPaid = true;
              },
            });
          }
        }
        //金豆收费
        if (res.data.permission == 1) {
          if (!res.data.mediaStatus.hasPaid) {
            //    console.log(this.photoList[this.currIndex].kind)
            _this.$bus.$emit("vipPopup", {
              id: this.photoList[this.currIndex].id,
              state: 2,
              goldenNum: res.data.price,
              videoType: this.photoList[this.currIndex].kind,
              closeBtn: () => {
               if (this.flag) {
                this.$bus.$emit("closeVipPopup");
                this.$router.go(-1);
                this.flag = false;
               }
              },
              buySuccess: () => {
                /**
                 * 金币购买成功
                 */
                _this.$bus.$emit("closeVipPopup");
                _this.atlasData.mediaStatus.hasPaid = true;
              },
            });
          }
        }
        this.atlasData = res.data;
        // console.log(this.atlasData," ----------------------")
        addVideoHistory(this.atlasData);
      }
    },
    onLoad() {
      this.loadImg = true;
    },
    goAdv(item) {
      jumpAdv(item);
    },
    backPage() {
      if (this.currIndex > 0) {
        this.atlasData = [];
        this.loadImg = false;
        this.currIndex--;
        if (this.photoList[this.currIndex].adv) {
          // console.log(this.)
          if (this.currIndex - 1 >= 0) {
            this.currIndex--;
          } else {
            Toast("没有更多了");
          }
        }
        this.getMediaInfoList();
      } else {
        Toast("没有更多了");
      }
    },
    nextPage() {
      if (this.currIndex < this.photoList.length - 1) {
        this.atlasData = [];
        this.loadImg = false;
        this.currIndex++;
        if (this.photoList[this.currIndex].adv) {
          if (this.currIndex + 1 <= this.photoList.length) {
            this.currIndex++;
          } else {
            Toast("没有更多了");
          }
        }
        this.getMediaInfoList();
      } else {
        Toast("没有更多了");
      }
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */
      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(doCollect, req);

      if (res.code == 200) {
        this.atlasData.mediaStatus.hasCollected = true;
        this.$toast({
          message: "收藏成功",
          position: "top",
        });
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */
      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(douUcollect, req);
      if (res.code == 200) {
        this.atlasData.mediaStatus.hasCollected = false;
        this.$toast({
          message: "取消收藏",
          position: "top",
        });
      }
    },
    async doPlayWorks() {
     let req = {
      kind: this.kind,
      mediaID: this.mediaID,
     };
     await this.$Api(doPlay, req);
    },
  },
};
</script>

<style lang="scss" scoped>
.portrayPage {
  height: 100%;
  overflow-y: auto;

  .content {
    height: 100vh;

    .advertising {
      display: flex;
      justify-content: center;
      padding: 20px 0;

      .advImg {
        width: 351px;
        height: 140px;
        border-radius: 6px;
        background: rgb(240, 240, 244);
        margin: 0 auto;

        /deep/ .van-image {
          border-radius: 8px;
        }

        /deep/ .van-image__img {
          border-radius: 8px;
        }
      }
    }

    .atlas {
      height: 100%;

      .atlasItem {
        width: 100%;
        height: auto;

        .previewImg {
          width: 100%;
          height: 100px;
        }
      }
    }
  }
  .headBar {
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: solid 1px rgb(43, 39, 74);

    .leftIcon {
      padding: 0 12px;
      img {
        width: 17px;
        height: 17px;
      }
    }

    .headTitle {
      width: 240px;
      height: 100%;
      // display: flex;
      // align-items: center;
      //justify-content: center;
      line-height: 40px;
      margin-right: 24px;
      font-weight: bold;
      font-size: 18px;
      color: #000000;

      span {
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .fictionCollect {
      //width: 100px;
      padding-right: 11px;
      margin-top: -5px;
      span {
       white-space: nowrap;
       padding: 4px 11px;
       color: #fff;
       font-size: 12px;
       border-radius: 20px;
       background: #000;
       background: rgba(129, 76, 255, 1);
      }
      .isCollect {
       background: #9E9E9E;
      }
      img {
        width: 60px;
        height: 24px;
        object-fit: fill;
      }
    }
  }
  .bottomBar {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: rgb(208, 206, 227);
    height: 100%;
    align-items: center;
    padding: 0 12px;
    color: rgb(0, 0, 0);

    img {
      width: 20px;
      height: 20px;
    }

    .icon {
     width: 20px;
     height: 20px;
     display: inline-block;
     vertical-align: middle;
     margin-right: 12px;
    }

    .up {
      img {
        margin-right: 12px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .down {
      .icon {
       transform: rotate(180deg);
       margin-right: 0;
      }
      img {
        margin-left: 12px;
        display: inline-block;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }

  /deep/ .van-overlay {
    background: rgba(0, 0, 0, 0);
  }
}
</style>
